import { ApiRequestParameters, TemplateStringTokens } from './apiClientUtils';

export default class ApiEndpointTemplate<T extends string> {
    url: string;
    constructor(url: T) {
        this.url = url;
    }

    build({ templateValues, queryParameters }: ApiRequestParameters<T>): string {
        let url = this.url;

        if (templateValues) {
            for (const key in templateValues) {
                if (Object.hasOwnProperty.call(templateValues, key)) {
                    const v = templateValues[key as TemplateStringTokens<T>];
                    url = url.replace(`{${key}}`, String(v));
                }
            }
        }

        if (queryParameters) {
            const parts = url.split('?');
            const baseUrl = parts.shift();
            if (!baseUrl) throw new Error('Bad url');
            url = baseUrl;
            const searchParams = new URLSearchParams(parts.join('&'));
            for (const key in queryParameters) {
                if (Object.hasOwnProperty.call(queryParameters, key)) {
                    const v = queryParameters[key];
                    if (v != null) {
                        searchParams.append(key, String(v));
                    }
                }
            }
            url = `${url}?${searchParams.toString()}`;
        }

        return url;
    }
}
