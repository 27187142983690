import ApiEndpoint from './ApiEndpoint';

export type HttpMethod = 'get' | 'post' | 'put' | 'delete';

export type TemplateStringTokens<T> = T extends `${string}{${infer T1}}${infer REST}`
    ? T1 | TemplateStringTokens<REST>
    : never;

export interface ApiRequestParameters<T extends string> {
    templateValues?: Record<TemplateStringTokens<T>, string | number | boolean>;
    queryParameters?: Record<string, string | number | boolean>;
    fetchOptions?: RequestInit;
    useAuth?: boolean;
}

export const mergeHeaderObjects = (
    a: Record<string, string>,
    b: HeadersInit | undefined
): HeadersInit | undefined => {
    return {
        ...a,
        ...b,
    };
};

type HasTemplateStringTokens<T> = T extends `${string}{${string}}${string}` ? true : false;

type TemplateOptions<T extends string> = HasTemplateStringTokens<T> extends true
    ? {
          templateValues: Record<TemplateStringTokens<T>, string | number | boolean>;
          queryParameters?: Record<string, string | number | boolean>;
      }
    : { queryParameters?: Record<string, string | number | boolean> } | undefined;

type BuildUrlMethodSignature<T extends string> = HasTemplateStringTokens<T> extends true
    ? (templateOptions: TemplateOptions<T>) => string
    : (templateOptions?: TemplateOptions<T>) => string;

type ApiEndpointOptions<T extends string, _M> = TemplateOptions<T> & {
    fetchOptions?: RequestInit;
    useAuth?: boolean;
};

type ApiEndpointSignatureForMethod<T extends string, M> = HasTemplateStringTokens<T> extends true
    ? (options: ApiEndpointOptions<T, M>) => Promise<Response>
    : (options?: ApiEndpointOptions<T, M>) => Promise<Response>;

type ApiEndpointPartial<T extends string, M extends HttpMethod> = {
    buildUrl: BuildUrlMethodSignature<T>;
} & { [K in M]: ApiEndpointSignatureForMethod<T, K> };

export const createEndpoint = <T extends string, M extends HttpMethod>(
    templateString: T,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _methods: M[]
): ApiEndpointPartial<T, M> => {
    return new ApiEndpoint(templateString);
};
